html {
  font-family: "Avenir", "Nunito", sans-serif;
  font-style: normal;
  font-weight: normal;
}

.center {
  display: block;
  margin: auto;
}

.NavigationBar {
  margin-top: 2em;
}

.NavigationBarText {
  font-size: 12px;
  line-height: 11px;
  text-align: center;
  color: #00306f;
}

.Introduction {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 132px;
  background: #F7F9FA;
  box-shadow: 0 50vh 0 50vh #F7F9FA;
}

.Card {
  position: relative;
  margin: auto;
  top: 50px;
  max-width: 500px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  padding: 76px;
  margin-bottom: 100px;
}

@media only screen and (max-width: 600px) {
  .Card {
    padding: 36px;
  }
}

.CardHeaderText {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #2f334a;
  margin-bottom: 20px;
}

.CardHeaderTextBackground {
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #2f334a;
  margin-bottom: 40px;
}
.SubHeaderText {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
}

.ParagraphText {

    font-size: 16px;
    line-height: 25px;
    color: #55576a;
    margin-top: 2em;
}

.InputLabel {
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: #47495e;
  margin-bottom: 5px;
}

.ShortAnswerQuestionContainer {
  margin-bottom: 1em;
}

.ShortAnswerQuestion {
  width: 100%;
  background: #fafafa;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 14px;
  color: #b3b3b3;
}

.LongAnswerQuestion {
  width: 100%;
  background: #fafafa;
  border: 1px solid #cccccc;
  box-sizing: border-box;
  font-size: 10px;
  line-height: 14px;
  color: #b3b3b3;
  margin-bottom: 2em;
  padding: 1em;
}

.ReviewPageLinks {
  text-decoration: none;
  color: #FB7F59;
  font-size: 11px;
}

.ImageContainer{
    width:128%;
    margin-left:-70px;
}
.dropzoneContainer button:not(:first-child) {
  top: 5px;
  right: 5px;
}

.dropzoneContainer div:not(:first-child) {
  display: flex;
  justify-content: center;
  padding: 3px;
}

.dropzoneContainer div div {
  padding: 10px;
}

.VideoUploadSection {
  padding-top: 1em;
  padding-bottom: 2em;
}

.VideoUploadDescription {
  display: block;
  font-size: 13px;
  line-height: 14px;
  color: #47495e;
  margin-bottom: 5px;
}

.VideoUploadDescription ul{
  margin-top: 0.5em;
}

.VideoUploadDescription li{
  font-size: 12px;
}

.VideoUploadFormats {
  color: #00306F;
  font-size: 10px;
}

.VideoUploadContainer {
  position: relative;
}

.VideoUploadButtonLabel {
  display: inline-block;
  padding: 8px 12px; 
  cursor: pointer;
  border-color: #2f334a;
  border-radius: 4px;
  border-style: solid;
  border-width: 2px;
  background-color: #ffffff;
  font-size: 10px;
  text-align: center;
  width: 55px;
  color: #2f334a;
  margin-top: 0.5em;
}

.VideoUploadButtonLabel:hover {
  background-color: aliceblue;
}

.VideoUploadFilename {
  margin-left: 0.5em;
  font-size: 13px;
  vertical-align: middle;
}

input[type="file"] {
  position: absolute;
  z-index: -1;
  top: 6px;
  left: 0;
  font-size: 15px;
  color: #2f334a;
}

.VideoUploadStatusImage {
  vertical-align: middle;
  margin-left: 0.5em;
  height: 1.5em;
  width: 1.5em;
}